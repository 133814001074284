<template>
  <div class="edit_form container mt-4">
    <h1>{{ t("user.create") }}</h1>
    <div v-if="error" class="w-100 text-center alert alert-danger mb-3" role="alert">
      <span v-if="error.message == 'The given data was invalid.'">{{ t("error.invalidData") }}</span>
      <span v-else>{{ error.message }}</span>
    </div>
    <form v-if="user.role === 0" ref="form" @submit.prevent class="needs-validation">
      <div class="mb-3">
        <label for="email" class="form-label">{{ t("user.email") }}</label>
        <input
          v-model="email"
          id="email"
          type="text"
          name="email"
          class="form-control"
          :placeholder="t('user.email')"
          :aria-label="t('user.email')"
          ref="f_email"
        />
        <div v-if="error?.errors?.email" class="invalid-feedback">
          <span v-for="(err, index) in error.errors.email" :key="index">{{ err }}</span>
        </div>
      </div>
      <div class="mb-3">
        <label for="name" class="form-label">{{ t("user.name") }}</label>
        <input
          v-model="name"
          id="name"
          type="text"
          name="name"
          class="form-control"
          :placeholder="t('user.name')"
          :aria-label="t('user.name')"
          ref="f_name"
        />
        <div v-if="error?.errors?.name" class="invalid-feedback">
          <span v-for="(err, index) in error.errors.name" :key="index">{{ err }}</span>
        </div>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">{{ t("user.password") }}</label>
        <input
          v-model="password"
          type="password"
          name="password"
          class="form-control"
          :aria-label="t('user.password_confirmation')"
          ref="f_password"
        />
        <div v-if="error?.errors?.password" class="invalid-feedback">
          <span v-for="(err, index) in error.errors.password" :key="index">{{ err }}</span>
        </div>
      </div>
      <div class="mb-3">
        <label for="password_confirmation" class="form-label">{{ t("user.password_confirmation") }}</label>
        <input
          v-model="password_confirmation"
          type="password"
          name="password_confirmation"
          class="form-control"
          :aria-label="t('user.password_confirmation')"
        />
      </div>
      <div class="mb-3">
        <label for="role">{{ t("user.role") }}</label>
        <select class="form-select" v-model="role" name="role" :aria-label="t('user.role')" ref="f_role">
          <option value="1">Benutzer</option>
          <option value="0">Administrator</option>
          <!-- <option value="10">Gast</option> -->
        </select>
        <div v-if="error?.errors?.role" class="invalid-feedback">
          <span v-for="(err, index) in error.errors.role" :key="index">{{ err }}</span>
        </div>
      </div>
      <button type="button" @click="create" class="btn btn-primary me-2">{{ t("general.save") }}</button>
      <button type="button" @click="cancel" class="btn btn-secondary">{{ t("general.cancel") }}</button>
    </form>
    <div v-else class="w-100 text-center alert alert-danger mb-3" role="alert">
      <span>{{ t("general.noPermission") }}</span>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import axios from "axios";
import storeConfig from "../../store/config";
import { useToast } from "vue-toastification";
export default {
  name: "UserCreate",
  setup() {
    const email = ref(null);
    const name = ref(null);
    const password = ref(null);
    const password_confirmation = ref(null);
    const role = ref(1);
    const error = ref(null);
    const store = useStore();

    const form = ref(null);
    const f_email = ref(null);
    const f_name = ref(null);
    const f_password = ref(null);
    const f_role = ref(null);

    const toast = useToast();
    const router = useRouter();
    const { t } = useI18n();

    async function create() {
      try {
        await store.dispatch("addLoading", 1);
        await axios.post(storeConfig.apiBaseUrl + "register", {
          email: email.value,
          password: password.value,
          password_confirmation: password_confirmation.value,
          name: name.value,
          role: role.value,
        });
        toast.success(t("user.user") + " " + t("general.successfully") + " " + t("general.added") + ".");
        router.push("/user");
      } catch (err) {
        error.value = err.response.data;
        // console.log("err", err.response.data);
        if (err.response.data.errors.email) {
          f_email.value.classList.add("is-invalid");
        }
        if (err.response.data.errors.name) {
          f_name.value.classList.add("is-invalid");
        }
        if (err.response.data.errors.password) {
          f_password.value.classList.add("is-invalid");
        }
        if (err.response.data.errors.role) {
          f_role.value.classList.add("is-invalid");
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    function cancel() {
      router.push("/user");
    }

    return {
      form,
      f_email,
      f_name,
      f_password,
      f_role,
      email,
      name,
      password,
      password_confirmation,
      role,
      error,
      create,
      cancel,
      user: computed(() => store.getters.user),
      jwt: computed(() => store.getters.jwt),
      t,
    };
  },
};
</script>
